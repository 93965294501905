import { Button } from 'react-bootstrap';
import { useCurrentRefinements } from 'react-instantsearch';

import { formatCategoryLabel } from './CategoryFilterItem';

export const SelectedFilters = () => {
  const { items } = useCurrentRefinements({ excludedAttributes: ['categories.lvl0', 'paused'] });
  const res = items.flatMap((item) =>
    item.refinements.map((refinement) => (
      <Button
        key={refinement.value}
        size="sm"
        variant="outline-accent"
        className="rounded-pill border-brownsugar text-brownsugar"
        onClick={() => item.refine(refinement)}
      >
        <>
          {formatCategoryLabel(refinement.label)}
          <i className="fi-x ms-2 fs-xs text-brownsugar" />
        </>
      </Button>
    ))
  );
  return res.length > 0 ? <div className="d-flex my-3 gap-2 flex-wrap">{res}</div> : null;
};
