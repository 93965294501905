import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { DEFAULT_DISTANCE, useSelectedDistance } from '@hooks';

export const useAvailableDistances = () =>
  ['10', '20', '30', '40', '50', DEFAULT_DISTANCE] as const;

export const DistanceFilterItem = () => {
  const { distance: selectedDistance, setDistance: setSelectedDistance } = useSelectedDistance();

  const availableDistances = useAvailableDistances();

  return (
    <div className="pb-4 mb-2">
      <h3 className="h2 mb-0 font-title text-primary">Distance</h3>
      <label className="d-block fs-sm mb-2">Within Miles</label>

      <div className="d-flex gap-1">
        {availableDistances.map((distance, indx) => (
          <ToggleButton
            key={indx}
            className="btn-xs p-2 rounded-pill"
            type="radio"
            id={`distance-${indx}`}
            checked={selectedDistance === distance}
            name="distance"
            value={distance}
            onChange={() => setSelectedDistance(distance)}
            variant="outline-primary"
            style={{ minWidth: '42px' }}
          >
            {distance}
          </ToggleButton>
        ))}
      </div>
    </div>
  );
};
