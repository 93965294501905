import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { adaptAddOrderItemVMToEventDetail } from '@components/ContactForm/adaptAddOrderItemVMToEventDetail';
import { CUSTOM_TIMEFRAME, NO_TIMEFRAME } from '@components/ContactForm/availableTimeframeOptions';
import { AddItemToCartFormVM } from '@components/ContactForm/constants';
import { DescriptionField } from '@components/ContactForm/DescriptionField';
import { QuantityField } from '@components/ContactForm/QuantityField';
import { CatalogItem } from '@hooks';
import {
  AddOrderItemToCartVM,
  OrderItemStoreVM,
  useCartOrder,
} from '@screens/CheckoutScreen/useCartOrder';
import { CartActionType } from '@screens/CheckoutScreen/useTrackCheckoutScreenEvents/types';
import { cartPubSub } from '@screens/CheckoutScreen/useTrackCheckoutScreenEvents/useTrackCartEvent';

import { DeliveryMethodFormGroup } from './DeliveryMethodFormGroup';
import { TimeframeFormGroup } from './TimeframeFormGroup';

type AddCartItemFormProps = {
  onSuccess?: (item: AddOrderItemToCartVM) => void;
  store: OrderItemStoreVM;
  catalogItem?: CatalogItem | undefined;
};
export const AddCartItemForm = ({ catalogItem, store, onSuccess }: AddCartItemFormProps) => {
  const { addOrderItem } = useCartOrder();
  const methods = useFormContext<AddItemToCartFormVM>();

  const onSubmit = async (values: AddItemToCartFormVM) => {
    if (catalogItem) {
      const timeframe =
        values?.timeframe?.value === CUSTOM_TIMEFRAME
          ? values.timeframe.customValue
          : values?.timeframe?.value === NO_TIMEFRAME
          ? undefined
          : values?.timeframe?.value;

      const addOrderItemVM: AddOrderItemToCartVM = {
        store,
        orderItem: catalogItem,
        quantity: values.quantity,
        note: values.note,
        timeframe: timeframe ?? undefined,
        delivery: values.delivery,
      };
      await addOrderItem(addOrderItemVM);

      cartPubSub.publish(CartActionType.AddToCart, {
        eventDetail: adaptAddOrderItemVMToEventDetail(addOrderItemVM),
      });

      onSuccess?.(addOrderItemVM);
    }
  };

  return (
    <Form
      className="d-flex flex-column gap-3 mt-lg-0 mt-4 h-lg-100"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      {catalogItem?.allowSelectQuantity && <QuantityField />}
      <TimeframeFormGroup />
      <DeliveryMethodFormGroup store={store} />
      <DescriptionField label="Additional request (optional)" />
      <div className="d-flex flex-grow-1 align-items-end">
        <Button
          size="lg"
          type="submit"
          className="rounded-pill text-light w-100 fw-bold gap-2 justify-content-center"
        >
          Add to cart
        </Button>
      </div>
    </Form>
  );
};
