import { omitBy, shuffle } from 'lodash/fp';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useUpdateEffect } from 'react-use';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { useCurrentScreen } from '@hooks';
import { CatalogItem, Store } from '@hooks/useStores';
import { ScreenName } from '@packages/event-tracking';
import { useGetUserDetailsPayload } from '@packages/event-tracking/useGetUserDetailsPayload';
import { OrderItemStoreVM } from '@screens/CheckoutScreen/useCartOrder';

import { catalogCarouselPubSub } from './catalogCarouselPubSub';
import { trackQuickShopCarouselEvent } from './constants';
import { ClickCarouselCardEventPayload } from './trackCatalogCarouselClickEvent/types';
import { CatalogCarouselDisplayEventName } from './useTrackCatalogCarouselDisplayEvent/types';
import { useTrackCatalogCarouselEvents } from './useTrackCatalogCarouselEvents';
import { CatalogCarouselScrollEventName } from './useTrackCatalogCarouselScrollEvent/types';
import { AddItemToCartModal } from '../CatalogCard/AddItemToCartModal';
import { CatalogCard } from '../CatalogCard/CatalogCard';

type CatalogCarouselProps = {
  categoryId?: number;
  taxonomyId?: number;
  searchTerm?: string;
  page?: number;
  stores: Store[];
  isFrom: ScreenName;
};

export const CatalogCarousel = ({
  stores,
  isFrom,
  categoryId,
  taxonomyId,
  searchTerm,
  page,
}: CatalogCarouselProps) => {
  const ref = useRef<SwiperRef>(null);

  useUpdateEffect(() => {
    ref.current?.swiper.slideTo(0);
  }, [stores]);

  useTrackCatalogCarouselEvents();

  const catalogItemsWithStore = useMemo(() => {
    const items = stores.flatMap((store) => store.catalog.map((item) => ({ store, item })));
    return shuffle(items);
  }, [stores]);

  const [cartItemModal, setCartItemModal] = useState<{
    show: boolean;
    item?: CatalogItem;
    store?: OrderItemStoreVM;
  }>({ show: false });
  const openCartItemModal = (item: CatalogItem, store: OrderItemStoreVM) =>
    setCartItemModal({ show: true, item, store });
  const closeCartItemModal = () => setCartItemModal((prev) => ({ ...prev, show: false }));
  const { screen: currentScreen } = useCurrentScreen();

  useEffect(
    () => {
      if (catalogItemsWithStore.length) {
        catalogCarouselPubSub.publish(CatalogCarouselDisplayEventName, {
          screen: currentScreen!,
          searchTerm,
          categoryId,
          taxonomyId,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogItemsWithStore]
  );
  const getUserDetailsPayload = useGetUserDetailsPayload();

  return (
    <div className="my-3">
      <AddItemToCartModal
        show={cartItemModal.show}
        store={cartItemModal.store}
        catalogItem={cartItemModal.item ?? undefined}
        onHide={closeCartItemModal}
      />
      <div className="position-relative px-2 px-md-3 mx-n2 fancy-swiper">
        <Swiper
          modules={[Pagination, Navigation]}
          ref={ref}
          spaceBetween={0}
          shortSwipes={true}
          pagination={{
            el: '#bullets',
            clickable: true,
          }}
          navigation={{
            prevEl: '#prev-quickshop-catalog',
            nextEl: '#next-quickshop-catalog',
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1200: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
          noSwiping={true}
          onSlideChange={({ activeIndex, previousIndex }: SwiperClass) => {
            catalogCarouselPubSub.publish(CatalogCarouselScrollEventName, {
              screen: currentScreen!,
              direction: activeIndex > previousIndex ? 'next' : 'previous',
            });
          }}
          noSwipingClass="yarl__slide"
        >
          {catalogItemsWithStore.map(({ item, store }, idx) => (
            <SwiperSlide className="p-2 h-100" key={`${item.name}${item.catalogId}`}>
              <CatalogCard
                item={item}
                store={store}
                isFrom={isFrom}
                openAddToCartModal={openCartItemModal}
                onClick={async () => {
                  const userDetails = await getUserDetailsPayload();
                  const payload: ClickCarouselCardEventPayload = {
                    eventDetail: omitBy((val) => val === '', {
                      businessName: store.businessName,
                      categoryId: store.categoryId,
                      categoryName: store.primaryCategory,
                      index: idx,
                      page: typeof page === 'number' ? page + 1 : null,
                      searchTerm,
                      storefrontId: store.id,
                    }),
                    ...userDetails,
                    component: {
                      action: 'click',
                      module: 'Quick Shop',
                      screen: currentScreen!,
                      component: 'carousel',
                    },
                  };
                  trackQuickShopCarouselEvent(payload);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button id="prev-quickshop-catalog" variant="prev" />
        <Button id="next-quickshop-catalog" variant="next" />
        <div
          id="bullets"
          className="swiper-pagination position-relative bottom-0 mt-4 mb-lg-3"
        ></div>
      </div>
    </div>
  );
};
