import Link from 'next/link';
import { MouseEventHandler } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { CatalogItem, Store } from '@hooks';
import { ScreenName, useBreadcrumbPayload } from '@packages/event-tracking';
import { adaptStoreToVM } from '@utils';
import { formatCurrency } from '@utils/formatCurrency';

import { ImageLoader } from '../ImageLoader';

export type CatalogCardProps = {
  item: CatalogItem;
  store: Store;
  openAddToCartModal: (item: CatalogItem, store: Store) => void; // AddOrderItemToCartVM
  isFrom: ScreenName;
  onClick: () => void;
};

export const CatalogCard = ({
  item,
  store,
  openAddToCartModal,
  isFrom,
  onClick,
}: CatalogCardProps) => {
  const breadcrumb = useBreadcrumbPayload();

  const onAddToCartClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openAddToCartModal(item, store);
    return false;
  };
  const storeVM = adaptStoreToVM(store);

  return (
    <>
      <Row className="h-100">
        <Col key={item.name} className="p-2" onClick={onClick}>
          <Link
            passHref
            href={{
              // pathname: `/shop/${item.uriSlug}/listing/${item.uriSlug}`, // <<< prefetch is not working
              pathname: `${storeVM.url}/listing/${item.uriSlug}`, // <<< prefetch is working
              query: {
                ...breadcrumb['user behavior'],
                isFrom,
              },
            }}
            as={`${storeVM.url}/listing/${item.uriSlug}`}
            style={{ all: 'unset' }}
          >
            <Card className="cursor-pointer rounded-4 shadow-lg border-0 h-100 overflow-hidden">
              <div className="d-flex justify-content-center position-relative ratio ratio-7x5">
                <ImageLoader
                  src={item.rawurl ? item.rawurl[0] : ''}
                  fill
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  alt={item.name}
                  unoptimized
                />
              </div>
              <Card.Body className="p-3 d-flex flex-column justify-content-between">
                <div>
                  <p className="mb-1 text-truncate">{item.name}</p>
                  <p
                    className="fs-xs mb-0 overflow-hidden text-secondary text-truncate text-truncate--2 mb-2"
                    style={{ height: 36, lineHeight: 1.5 }}
                  >
                    {item.description}
                  </p>
                  <div className="d-flex gap-2 align-items-start mb-2 pb-1 pt-1">
                    <div
                      style={{ width: 42, height: 42 }}
                      className="rounded position-relative flex-shrink-0 overflow-hidden"
                    >
                      <ImageLoader
                        fill
                        style={{ objectFit: 'cover', objectPosition: 'center' }}
                        src={
                          storeVM.ownerImageSrc ??
                          '/images/store-details/review-author-default-image.png'
                        }
                        alt={storeVM.owner}
                      />
                    </div>
                    <div className="overflow-hidden">
                      <div className="text-primary fs-sm font-serif text-truncate">
                        {storeVM.name}
                      </div>
                      <div className="text-primary fs-xs font-serif text-truncate">
                        {storeVM.location}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="mb-2 pb-1" />
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div>
                      <p className="mb-0">
                        <span className="h2 font-title text-primary">
                          {formatCurrency(item.price)}
                        </span>
                      </p>
                    </div>
                    <Button
                      size="sm"
                      onClick={onAddToCartClick}
                      className="d-flex align-items-center rounded-pill text-light justify-content-center"
                    >
                      <span className="fs-7">Add to Cart</span>
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </>
  );
};
