import { useEffect, useMemo, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { usePagination, useSearchBox } from 'react-instantsearch';

import { About, DownloadApp, Footer, Header, HeaderWithSearch, StoreSearch } from '@components';
import { CatalogCarousel } from '@components/CatalogCarousel/CatalogCarousel';
import { SidebarLayout } from '@components/SidebarLayout';
import { useCurrentScreen, useStores } from '@hooks';
import { useUserLocation } from '@hooks/useUserLocation';
import { ScreenName } from '@packages/event-tracking';
import { CartActionType } from '@screens/CheckoutScreen/useTrackCheckoutScreenEvents/types';
import { useTrackCartEvent } from '@screens/CheckoutScreen/useTrackCheckoutScreenEvents/useTrackCartEvent';

import { CategoryFilter, CategoryScreenTitle, SearchResults, SelectedFilters } from './components';
import { CategoryScreenProps, CategoryScreenVariant } from './types';

export const CategoryScreen = (props: CategoryScreenProps) => {
  const offcanvasContainer = useRef(null);

  const { refine: refineSearch, query } = useSearchBox();

  const categoryName =
    props.variant === CategoryScreenVariant.Category
      ? props.title
      : props.variant === CategoryScreenVariant.SuperCategory
      ? props.title
      : undefined;

  const id =
    props.variant === CategoryScreenVariant.Category
      ? props.item?.id
      : props.variant === CategoryScreenVariant.SuperCategory
      ? props.id
      : undefined;

  const { currentRefinement: currentPage } = usePagination();

  const searchResultsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (searchResultsContainerRef.current) {
      searchResultsContainerRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }
  }, [currentPage]);

  const baseEventPayload = useMemo(
    () => ({
      categoryName,
      categoryId: props.variant === CategoryScreenVariant.Category ? id : undefined,
      taxonomyId: props.variant === CategoryScreenVariant.SuperCategory ? id : undefined,
    }),
    [categoryName, id, props.variant]
  );

  const displayResultsTotal = id !== undefined || query !== '';

  const { location } = useUserLocation();

  const { screen } = useCurrentScreen();
  useTrackCartEvent(CartActionType.AddToCart, 'Carousel', 'button', 'Add to Cart');
  const { query: searchTerm } = useSearchBox();

  return (
    <main className="page-wrapper" ref={searchResultsContainerRef}>
      <SidebarLayout>
        {props.variant === CategoryScreenVariant.SearchResults ? (
          <Header extra={<StoreSearch query={query} onSearch={refineSearch} />} />
        ) : (
          <HeaderWithSearch shouldTrackEvent={false} />
        )}
        <Container fluid className="p-0">
          <Row className="g-0 ">
            <Col
              ref={offcanvasContainer}
              as="aside"
              lg={4}
              xl={3}
              className="d-flex border-end-lg shadow-sm px-3 px-xl-4 px-xxl-5 pt-lg-2"
            >
              <CategoryFilter
                parentCategory={
                  props.variant === CategoryScreenVariant.SuperCategory ? props.name : undefined
                }
                ref={offcanvasContainer}
              />
            </Col>

            <Col
              lg={8}
              xl={9}
              className="position-relative overflow-hidden pb-5 pt-4 px-3 px-xl-4 px-xxl-5"
            >
              <SelectedFilters />
              <CategoryScreenTitle {...props} />
              <CatalogCarousel
                categoryId={baseEventPayload.categoryId}
                taxonomyId={baseEventPayload.taxonomyId}
                searchTerm={searchTerm}
                page={currentPage}
                isFrom={screen || ScreenName.Browse}
                stores={useStores().stores ?? []}
              />
              <SearchResults
                location={location}
                defaultPayload={baseEventPayload}
                showTotal={displayResultsTotal}
              />
            </Col>
          </Row>
          <DownloadApp />
        </Container>
        <Footer />
      </SidebarLayout>
    </main>
  );
};
