import { AddOrderItemToCartVM } from '@screens/CheckoutScreen/useCartOrder';
import { CartOrderEventPayload } from '@screens/CheckoutScreen/useTrackCheckoutScreenEvents/types';

export const adaptAddOrderItemVMToEventDetail = (
  addOrderItemVM: AddOrderItemToCartVM
): Omit<CartOrderEventPayload['eventDetail'], 'distance'> => ({
  additionalRequestProvided: Boolean(
    addOrderItemVM.timeframe ||
      addOrderItemVM.delivery?.method ||
      addOrderItemVM.note ||
      addOrderItemVM.delivery?.location
  ),
  businessName: addOrderItemVM.store.businessName,
  storefrontId: addOrderItemVM.store.id.toString(),
  catalogItemName: addOrderItemVM.orderItem.name,
  catalogId: addOrderItemVM.orderItem.catalogId.toString(),
  quantity: addOrderItemVM.quantity,
  timeframe: addOrderItemVM.timeframe ?? undefined,
  fulfillmentMethod: addOrderItemVM.delivery?.method,
  price: addOrderItemVM.orderItem.price,
});
