import { useSearchBox } from 'react-instantsearch';

import { ImageLoader } from '@components';

import { CategoryScreenProps, CategoryScreenVariant } from '../types';

export const CategoryScreenTitle = (props: CategoryScreenProps) => {
  const { query } = useSearchBox();
  return (
    <>
      {props.variant === CategoryScreenVariant.SearchResults && query ? (
        <h6 className="text-dark">Nearby search results for &quot;{query}&quot;</h6>
      ) : null}
      {props.variant === CategoryScreenVariant.Category && props.parentCategory ? (
        <h6 className="text-accent fw-bolder fst-italic d-flex align-items-center">
          {props.parentCategory.icon ? (
            <span className="d-flex me-2">
              <ImageLoader
                src={props.parentCategory.icon}
                width={24}
                height={24}
                style={{ objectFit: 'contain' }}
                alt=""
              />
            </span>
          ) : null}
          {props.parentCategory.title}
        </h6>
      ) : null}
      {props.variant !== CategoryScreenVariant.SearchResults ? (
        <div className="d-sm-flex flex-column align-items-start justify-content-between">
          <h1 className="mb-sm-0 text-primary font-title">{props?.title}</h1>
          {props?.description ? <p className="text-gray mt-1 mb-0">{props.description}</p> : null}
        </div>
      ) : null}
    </>
  );
};
