import { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';

import {
  availableTimeframeOptions,
  CUSTOM_TIMEFRAME,
} from '@components/ContactForm/availableTimeframeOptions';
import { AddItemToCartFormVM } from '@components/ContactForm/constants';
import { DatePickerField } from '@components/ContactForm/DatePickerField';
import { SelectField } from '@components/SelectField';

export const TimeframeFormGroup = () => {
  const methods = useFormContext<AddItemToCartFormVM>();

  const selectedTimeframeValue = methods.watch('timeframe.value');

  const datePickerRef = useRef<DatePicker<string, boolean | undefined>>(null);
  useEffect(() => {
    if (selectedTimeframeValue === CUSTOM_TIMEFRAME) {
      datePickerRef.current?.setFocus();
      datePickerRef.current?.setOpen(true);
    }
  }, [selectedTimeframeValue]);

  return (
    <>
      <SelectField
        label="Select timeframe"
        name="timeframe.value"
        rules={{
          deps: ['timeframe'],
        }}
        control={methods.control}
        options={availableTimeframeOptions.concat([{ value: CUSTOM_TIMEFRAME, label: 'Custom' }])}
      />
      {selectedTimeframeValue === CUSTOM_TIMEFRAME ? (
        <DatePickerField
          ref={datePickerRef}
          control={methods.control}
          name="timeframe.customValue"
          onBlur={() => methods.trigger('timeframe')}
        />
      ) : null}
      {methods.formState.errors.timeframe && (
        <Form.Text className="text-danger fs-xs mt-n2">
          {methods.formState.errors.timeframe?.message}
        </Form.Text>
      )}
    </>
  );
};
