import { useEffect } from 'react';

import { useGetUserDetailsPayload } from '@packages/event-tracking/useGetUserDetailsPayload';

import { CatalogCarouselScrollEventName, ScrollCarouselEventPayload } from './types';
import { catalogCarouselPubSub } from '../catalogCarouselPubSub';
import { trackQuickShopCarouselEvent } from '../constants';

export const useTrackCatalogCarouselScrollEvent = () => {
  const getUserDetailsPayload = useGetUserDetailsPayload();
  useEffect(() => {
    const scrollCarouselUnsubscribeToken = catalogCarouselPubSub.subscribe(
      CatalogCarouselScrollEventName,
      async ({ screen, ...eventDetail }) => {
        const userDetails = await getUserDetailsPayload();
        const payload: ScrollCarouselEventPayload = {
          eventDetail,
          ...userDetails,
          component: {
            action: 'scroll',
            module: 'Quick Shop',
            screen,
            component: 'carousel',
          },
        };
        trackQuickShopCarouselEvent(payload);
      }
    );
    return () => {
      catalogCarouselPubSub.unsubscribe(scrollCarouselUnsubscribeToken);
    };
  }, [getUserDetailsPayload]);
};
