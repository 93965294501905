import { UserDetailsPayload } from '@packages/event-tracking/useGetUserDetailsPayload';

import { CatalogCarouselComponent } from '../types';

type DisplayCarouselEventDetail = {
  categoryId?: number;
  taxonomyId?: number;
  searchTerm?: string;
};

export type DisplayCarouselEventPayload = UserDetailsPayload & {
  component: CatalogCarouselComponent<'view'>;
  eventDetail: DisplayCarouselEventDetail;
};

export const CatalogCarouselDisplayEventName = 'QS Carousel Display';
