import { useRouter } from 'next/router';
import { Button, CloseButton, Col, Modal, ModalProps, Row } from 'react-bootstrap';

import { StoreDetailsImageCarousel } from '@components';
import { formatCurrency } from '@utils/formatCurrency';

import { AddOrderItemToCartVM } from './useCartOrder';

type ItemAddedToCartModalProps = ModalProps & {
  cartItem?: AddOrderItemToCartVM;
};

export const ItemAddedToCartModal = ({ cartItem, ...modalProps }: ItemAddedToCartModalProps) => {
  const router = useRouter();

  return (
    <Modal centered size="xl" {...modalProps}>
      <Modal.Body style={{ minHeight: 500 }}>
        <CloseButton
          onClick={modalProps.onHide}
          aria-label="Close modal"
          className="position-absolute top-0 end-0 mt-2 me-2"
        />
        <Row className="row mx-0 align-items-stretch py-lg-5 px-lg-5 py-xl-7 px-xl-7 mt-3">
          <Col lg={6}>
            <div className="d-flex flex-column-reverse flex-lg-column">
              <div className="w-100 position-relative">
                <StoreDetailsImageCarousel
                  objectFit="cover"
                  size={{ width: 420, height: 315 }}
                  images={cartItem?.orderItem.rawurl?.map((p) => ({ name: p, uri: p })) ?? []}
                />
              </div>
            </div>
          </Col>
          <Col lg={{ span: 5, offset: 1 }} className="d-flex justify-content-center">
            <div className="d-flex  w-100 p-3 align-items-center">
              <div className="mb-4 mb-lg-0">
                <h3 className="fst-italic font-serif text-brownsugar fw-bolder mb-2">
                  Added to cart
                </h3>
                <h3 className="text-dark fw-bold mt-4">{cartItem?.orderItem.name}</h3>
                <hr className="my-3" />
                {cartItem?.orderItem.price ? (
                  <h4 className="text-dark fw-bolder mb-0">
                    {cartItem.quantity ?? 1} x {formatCurrency(cartItem?.orderItem.price)}
                  </h4>
                ) : null}
              </div>
            </div>
          </Col>
          <div className="d-flex align-items-end justify-content-center mt-lg-6 mt-1">
            <div className="w-100 d-flex flex-column flex-sm-row gap-3 px-0 px-sm-3 px-md-6">
              <Button
                size="lg"
                type="submit"
                variant="outline-primary"
                onClick={modalProps.onHide}
                className="rounded-pill d-flex align-items-center rounded-pill w-100 fw-bold gap-2 justify-content-center"
              >
                <span>Continue Shopping</span>
              </Button>
              <Button
                size="lg"
                type="submit"
                onClick={() => router.push('/order/checkout')}
                className="d-flex align-items-center rounded-pill text-light w-100 fw-bold gap-2 justify-content-center  "
              >
                <span>View Cart</span>
              </Button>
            </div>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
