import { Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

export const DeliveryOptionFilterItem = ({
  items: deliveryOptions,
  refine: refineDeliveryOptions,
}: import('instantsearch.js/es/connectors/refinement-list/connectRefinementList').RefinementListRenderState) => {
  return deliveryOptions.length ? (
    <div className="pb-4 mb-2">
      <h3 className="h2 mb-2 font-title text-primary">Delivery Options</h3>
      <SimpleBar autoHide={false} className="simplebar-no-autohide">
        {deliveryOptions.map(({ value, label, isRefined, count }, indx) => (
          <Form.Check
            key={indx}
            id={`type-${indx}`}
            value={value}
            checked={isRefined}
            onChange={() => refineDeliveryOptions(value)}
            label={
              <span className="fs-sm">
                {label} ({count})
              </span>
            }
          />
        ))}
      </SimpleBar>
    </div>
  ) : null;
};
