import { UserDetailsPayload } from '@packages/event-tracking/useGetUserDetailsPayload';

import { CatalogCarouselComponent } from '../types';

type ScrollCarouselEventDetail = {
  direction: 'previous' | 'next';
};

export type ScrollCarouselEventPayload = UserDetailsPayload & {
  component: CatalogCarouselComponent<'scroll'>;
  eventDetail: ScrollCarouselEventDetail;
};

export const CatalogCarouselScrollEventName = 'QS Carousel Scroll';
