import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSessionStorage } from 'react-use';

import { ImageLoader } from '@components';

const steps = [
  {
    title: 'Message with a small business owner',
    icon: '/images/chat.svg',
    description: 'Interact with a real person to request a custom item or service',
  },
  {
    title: 'Confirm the details',
    icon: '/images/sales.svg',
    description: 'Get a response (usually within 24 hours) & agree to a price and timeframe',
  },
  {
    title: 'Consider it done',
    icon: '/images/shipping.svg',
    description:
      'Receive high-quality products and/or life-enhancing services from our incredible small business owners',
  },
];

export const HowItWorks = () => {
  const [isFirstRender, setIsFirstRender] = useSessionStorage('how-it-works-first-render', true);

  useEffect(() => {
    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventKey = '0';
  const [activeKey, setActiveKey] = useState(isFirstRender ? eventKey : undefined);

  return (
    <Accordion
      className="mb-4"
      flush
      onClick={() => setActiveKey((currentKey) => (currentKey ? undefined : eventKey))}
      activeKey={activeKey}
    >
      <Accordion.Item eventKey={eventKey} className="border-none bg-light rounded-2 p-4">
        <div className="border-none d-flex">
          <Accordion.Button className="fs-lg p-0 bg-light rounded-2 font-serif">
            How it works
            <hr className="flex-grow-1 mx-3" />
          </Accordion.Button>
        </div>
        <Accordion.Body className="p-0 mt-3">
          <ol className="fw-bold fst-italic font-serif text-dark ps-6 mb-0">
            {steps.map((step) => (
              <li key={step.title}>
                <span className="position-relative">
                  {step.icon ? (
                    <span
                      className="position-absolute d-flex"
                      style={{
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '-3rem',
                      }}
                    >
                      <ImageLoader src={step.icon} width={20} height={20} alt="chat" />
                    </span>
                  ) : null}{' '}
                  {step.title}
                </span>
                <p className="mb-0 fw-normal fst-normal font-sans">{step.description}</p>
              </li>
            ))}
          </ol>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
