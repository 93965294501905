import { useClearRefinements } from 'react-instantsearch';

import { DEFAULT_DISTANCE, useSelectedDistance } from '@hooks';

export const useResetFilters = ({ withCategoriesFilter }: { withCategoriesFilter: boolean }) => {
  const { distance: selectedDistance, setDistance: setSelectedDistance } = useSelectedDistance();
  const { canRefine: canReset, refine: resetFilter } = useClearRefinements({
    excludedAttributes: [
      'paused',
      'categories.lvl0',
      withCategoriesFilter ? '' : 'categories.lvl1',
    ].filter(Boolean),
  });

  return {
    resetFilter: () => {
      resetFilter(), setSelectedDistance();
    },
    canReset: canReset || selectedDistance !== DEFAULT_DISTANCE,
  };
};
