import { useMemo } from 'react';
import { useCurrentRefinements, usePagination, useSearchBox } from 'react-instantsearch';

import { useSelectedDistance } from '@hooks';
import { BreadcrumbPayload } from '@packages/event-tracking';

const emptyArray: string[] = [];

export type DefaultPayload = Pick<
  BreadcrumbPayload['user behavior'],
  'category name' | 'categoryId' | 'marketKey' | 'taxonomyId'
>;

export const useUserBehaviorEventPayload = (defaultPayload: DefaultPayload) => {
  const { items } = useCurrentRefinements({
    includedAttributes: ['fulfillmentOptions', 'categories.lvl1'],
  });

  const [selectedDeliveryOptions, selectedCategories] = useMemo(
    () =>
      items.map(
        (refinement) => refinement?.refinements.map((r) => r.value.toString()) ?? emptyArray
      ),
    [items]
  );
  const { distance } = useSelectedDistance();

  const { currentRefinement: currentPage } = usePagination();
  const { query } = useSearchBox();

  return useMemo(
    (): Omit<BreadcrumbPayload['user behavior'], 'isFrom'> => ({
      ...defaultPayload,
      'delivery method filters': selectedDeliveryOptions,
      distanceFilter: distance,
      'search term': query,
      categoryFilter: selectedCategories,
      pageNumber: currentPage + 1,
    }),
    [currentPage, distance, query, selectedCategories, selectedDeliveryOptions, defaultPayload]
  );
};
