import { SuperCategoryEntity } from '@api';

export enum CategoryScreenVariant {
  Category = 'category',
  SuperCategory = 'super-category',
  SearchResults = 'search-results',
  Market = 'market',
}

export type CategoryProps = {
  title?: string;
  description?: string;
  item: {
    id: number;
  } | null;
  parentCategory?: Pick<SuperCategoryEntity, 'title' | 'icon'>;
  variant: CategoryScreenVariant.Category;
};

export type CategoryScreenProps =
  | CategoryProps
  | {
      title?: string;
      id?: number;
      description?: string;
      name: string;
      icon?: string;
      variant: CategoryScreenVariant.SuperCategory;
    }
  | { variant: CategoryScreenVariant.Market; title?: string; description?: string }
  | { variant: CategoryScreenVariant.SearchResults };
