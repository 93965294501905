import type { UiState } from 'instantsearch.js';
import { history } from 'instantsearch.js/es/lib/routers';
import { InstantSearchProps } from 'react-instantsearch';

import { searchIndex } from '@constants';

import { categoryFilterSerializer } from './categoryFilterSerializer';

export const getAlgoliaRouting = ({
  path,
  filters,
  parentCategory,
}: {
  path: string;
  filters: string;
  parentCategory?: string;
}): InstantSearchProps<UiState>['routing'] => ({
  router: history({
    createURL({ routeState, location }) {
      // if page changed, we don't parse filters
      const state = routeState[searchIndex];
      if (!location.href.includes(path) || !state) {
        return location.href;
      }

      const formattedFilters = categoryFilterSerializer.serialize({
        query: typeof state.query === 'string' ? [state.query] : undefined,
        page: state.page && state.page !== 1 ? [state.page.toString()] : undefined,
        fulfillments: state.refinementList?.fulfillmentOptions,
        categories: state.refinementList?.['categories.lvl1'],
      });
      const regex = new RegExp(`/^(.*?)\/${path}/`);
      const urlParts = location.href.match(regex);
      const baseUrl = `${urlParts ? urlParts[1] : ''}/`;
      return `${baseUrl}${path}${formattedFilters ? `/${formattedFilters}` : ''}`;
    },

    parseURL() {
      const {
        query = [],
        page = [],
        fulfillments = [],
        categories = [],
      } = categoryFilterSerializer.deserialize(filters);

      return {
        [searchIndex]: {
          query: query[0],
          page: +page[0],
          refinementList: {
            fulfillmentOptions: fulfillments,
            paused: ['false'],
            'categories.lvl0': parentCategory ? [decodeURIComponent(parentCategory)] : [],
            'categories.lvl1': categories,
          },
        },
      };
    },
  }),

  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[searchIndex] || {};
      return {
        [searchIndex]: {
          ...indexUiState,
          fulfillmentOptions:
            indexUiState.refinementList && indexUiState.refinementList.fulfillmentOptions,
        },
      };
    },

    routeToState(routeState) {
      const state = routeState[searchIndex];
      return {
        [searchIndex]: {
          ...state,
          query: state.query,
          page: state.page,
          refinementList: {
            'categories.lvl1': state.refinementList?.['categories.lvl1'] ?? [],
            fulfillmentOptions: state.refinementList?.fulfillmentOptions ?? [],
            paused: ['false'],
            'categories.lvl0': parentCategory ? [parentCategory] : [],
          },
        },
      };
    },
  },
});
