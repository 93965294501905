import { Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import { getTextAfterSymbol } from './getTextAfterSymbol';

export const formatCategoryLabel = getTextAfterSymbol('>');

export const CategoryFilterItem = ({
  items,
  refine: refineCategories,
}: import('instantsearch.js/es/connectors/refinement-list/connectRefinementList').RefinementListRenderState) => {
  return (
    <div className="pb-4 mb-2">
      <h3 className="h2 mb-2 font-title text-primary">Categories</h3>
      <SimpleBar autoHide={false} className="simplebar-no-autohide">
        {items?.map(({ label, value, isRefined }: any, indx: number) => (
          <Form.Check
            key={indx}
            id={`category-${indx}`}
            value={value}
            checked={isRefined}
            onChange={() => refineCategories(value)}
            label={<span className="fs-sm">{formatCategoryLabel(label)}</span>}
          />
        ))}
      </SimpleBar>
    </div>
  );
};
