import PubSub from 'pubsub-js';
import { useEffect } from 'react';

import {
  BreadcrumbPayload,
  EventName,
  trackEvent,
  useGetBaseEventPayload,
} from '@packages/event-tracking';
import { fbq } from '@packages/fbpixel';
import { ga4 } from '@packages/ga4';

const isBrowseAllNearby = (userBehavior: BreadcrumbPayload['user behavior']) =>
  !userBehavior['search term'] &&
  !userBehavior['category name'] &&
  !userBehavior['taxonomyId'] &&
  !userBehavior['categoryId'];

export const useTrackBrowseAndSearchEvents = (
  userBehavior: Omit<BreadcrumbPayload['user behavior'], 'isFrom'>
) => {
  const getBaseEventPayload = useGetBaseEventPayload();

  useEffect(() => {
    (async () => {
      const baseEventPayload = await getBaseEventPayload();

      if (baseEventPayload) {
        trackEvent(EventName.BrowseCategory, {
          'user behavior': isBrowseAllNearby(userBehavior)
            ? { ...userBehavior, 'category name': 'Browse All Nearby', categoryId: 10000 }
            : userBehavior,
          ...baseEventPayload,
        });
      }
    })();
  }, [userBehavior, getBaseEventPayload]);

  useEffect(() => {
    const searchStoresToken = PubSub.subscribe(
      EventName.SearchStores,
      async (_: unknown, searchTerm: string) => {
        const baseEventPayload = await getBaseEventPayload();

        if (baseEventPayload && searchTerm) {
          const payload = {
            'user behavior': { ...userBehavior, 'search term': searchTerm },
            ...baseEventPayload,
          };
          trackEvent(EventName.SearchStores, payload);
          fbq.searchEvent({ customProperties: payload, search_string: searchTerm });
          ga4.searchEvent({ customProperties: payload, search_string: searchTerm });
        }
      }
    );

    return () => {
      PubSub.unsubscribe(searchStoresToken);
    };
  }, [getBaseEventPayload, userBehavior]);
};
