import nProgress from 'nprogress';
import { useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { usePagination } from 'react-instantsearch';
import { useUnmount } from 'react-use';

import { PAGE_SIZE, Pagination, StoreCard } from '@components';
import { DEFAULT_DISTANCE, PlaceLocation, useSelectedDistance, useStores } from '@hooks';
import { adaptStoreToVM } from '@utils';

import { useTrackBrowseAndSearchEvents } from './useTrackBrowseAndSearchEvents';
import { DefaultPayload, useUserBehaviorEventPayload } from './useUserBehaviorEventPayload';

const formatDistance = (distance: string) =>
  distance === DEFAULT_DISTANCE ? 'Anywhere' : `${distance} Miles`;

export const SearchResults = ({
  showTotal = true,
  noTitle,
  defaultPayload,
  location,
}: {
  showTotal?: boolean;
  noTitle?: boolean;
  defaultPayload: DefaultPayload;
  location?: PlaceLocation;
}) => {
  const { stores, loading } = useStores();
  const { distance } = useSelectedDistance();

  const behaviorPayload = useUserBehaviorEventPayload(defaultPayload);
  useTrackBrowseAndSearchEvents(behaviorPayload);

  useEffect(() => {
    if (loading) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [loading]);

  const {
    refine,
    nbHits: total,
    nbPages,
    currentRefinement: currentPage,
    isFirstPage,
    isLastPage,
  } = usePagination();

  const { setDistance } = useSelectedDistance();
  useUnmount(() => {
    setDistance(DEFAULT_DISTANCE);
  });

  return (
    <>
      {noTitle ? null : (
        <div className="mb-3 d-flex flex-sm-row justify-content-between align-items-sm-center align-items-stretch border-bottom border-primary">
          <span className="text-nowrap fw-bolder">
            Businesses
            {`${distance === DEFAULT_DISTANCE ? '' : ' within'}  ${formatDistance(distance)}`}
          </span>
          <div className="d-none d-sm-flex align-items-center flex-shrink-0 text-primary">
            {showTotal && <span className="fs-sm">{total} businesses</span>}
          </div>
        </div>
      )}
      {stores.length ? (
        <Row xs={1} sm={1} md={2} lg={2} xl={2} xxl={2} className="py-0 mt-0">
          {stores.map(adaptStoreToVM).map((store) => (
            <Col className="p-2 mt-2" key={store.name}>
              <StoreCard
                item={store}
                direction="vertical"
                url={{ pathname: store.url, query: behaviorPayload }}
              />
            </Col>
          ))}
        </Row>
      ) : loading ? (
        <div className="d-flex justify-content-center my-3">
          <Spinner animation="border" variant="accent" role="status" className="m-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <p>
          We don&apos;t have any businesses
          {`${distance === DEFAULT_DISTANCE ? '' : ` within ${formatDistance(distance)}`}`}
          {location?.formatted_address ? ` from ${location?.formatted_address}` : ''}.
        </p>
      )}
      {nbPages > 1 && (
        <nav aria-label="Pagination" className="mt-4">
          <Pagination
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            total={total}
            currentPage={currentPage + 1}
            pageSize={PAGE_SIZE}
            onPageChange={(page) => refine(page - 1)}
          />
        </nav>
      )}
    </>
  );
};
