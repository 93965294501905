import { ScreenName } from '@packages/event-tracking';

import {
  CatalogCarouselClickEventName,
  ClickCarouselCardEventPayload,
} from './trackCatalogCarouselClickEvent/types';
import {
  CatalogCarouselDisplayEventName,
  DisplayCarouselEventPayload,
} from './useTrackCatalogCarouselDisplayEvent/types';
import {
  CatalogCarouselScrollEventName,
  ScrollCarouselEventPayload,
} from './useTrackCatalogCarouselScrollEvent/types';

type CatalogCarouselEventPayloadDetailByName = {
  [CatalogCarouselClickEventName]: ClickCarouselCardEventPayload;
  [CatalogCarouselScrollEventName]: ScrollCarouselEventPayload;
  [CatalogCarouselDisplayEventName]: DisplayCarouselEventPayload;
};

export const catalogCarouselPubSub = {
  publish: <T extends keyof CatalogCarouselEventPayloadDetailByName>(
    name: T,
    payload: { screen: ScreenName } & CatalogCarouselEventPayloadDetailByName[T]['eventDetail']
  ) => {
    PubSub.publish(name, payload);
  },
  subscribe: <T extends keyof CatalogCarouselEventPayloadDetailByName>(
    name: T,
    fn: (
      payload: { screen: ScreenName } & CatalogCarouselEventPayloadDetailByName[T]['eventDetail']
    ) => void
  ) => PubSub.subscribe(name, (_message, payload) => fn(payload)),
  unsubscribe: (token: string) => PubSub.unsubscribe(token),
};
