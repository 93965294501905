import { omitBy } from 'lodash/fp';
import { useEffect } from 'react';

import { useGetUserDetailsPayload } from '@packages/event-tracking/useGetUserDetailsPayload';

import { CatalogCarouselDisplayEventName, DisplayCarouselEventPayload } from './types';
import { catalogCarouselPubSub } from '../catalogCarouselPubSub';
import { trackQuickShopCarouselEvent } from '../constants';

export const useTrackCatalogCarouselDisplayEvent = () => {
  const getUserDetailsPayload = useGetUserDetailsPayload();
  useEffect(() => {
    const displayCarouselUnsubscribeToken = catalogCarouselPubSub.subscribe(
      CatalogCarouselDisplayEventName,
      async ({ screen, ...eventDetail }) => {
        const userDetails = await getUserDetailsPayload();
        const payload: DisplayCarouselEventPayload = {
          eventDetail: omitBy((val) => val === '', eventDetail),
          ...userDetails,
          component: {
            action: 'view',
            module: 'Quick Shop',
            screen,
            component: 'carousel',
          },
        };
        trackQuickShopCarouselEvent(payload);
      }
    );
    return () => {
      catalogCarouselPubSub.unsubscribe(displayCarouselUnsubscribeToken);
    };
  }, [getUserDetailsPayload]);
};
