import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { AddItemToCartFormVM } from '@components/ContactForm/constants';
import { shouldRenderLocation } from '@components/ContactForm/shouldRenderLocation';
import { DeliveryMethodSelectField } from '@screens/CheckoutScreen/DeliveryMethodSelectField';
import { OrderItemStoreVM } from '@screens/CheckoutScreen/useCartOrder';

export const DeliveryMethodFormGroup = ({ store }: { store: OrderItemStoreVM }) => {
  const methods = useFormContext<AddItemToCartFormVM>();

  const selectedDeliveryMethod = methods.watch('delivery.method');

  return (
    <>
      <DeliveryMethodSelectField store={store} name="delivery.method" control={methods.control} />
      {selectedDeliveryMethod && shouldRenderLocation(selectedDeliveryMethod) && (
        <Form.FloatingLabel label="Address">
          <Form.Control
            {...methods.register('delivery.location', { deps: ['delivery'] })}
            name="delivery.location"
            placeholder="Address"
          />
        </Form.FloatingLabel>
      )}
      {methods.formState.errors.delivery?.message && (
        <Form.Text className="text-danger fs-xs mt-n2">
          {methods.formState.errors.delivery?.message}
        </Form.Text>
      )}
    </>
  );
};
