import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { CloseButton, Col, Modal, ModalProps, Row } from 'react-bootstrap';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

import { AddItemToCartFormVM } from '@components/ContactForm/constants';
import { StoreDetailsImageCarousel } from '@components/StoreDetailsImageCarousel';
import { CatalogItem } from '@hooks';
import { ItemAddedToCartModal } from '@screens/CheckoutScreen/ItemAddedToCartModal';
import { addCartItemFormSchema } from '@screens/CheckoutScreen/updateCartItemFormSchema';
import { AddOrderItemToCartVM, OrderItemStoreVM } from '@screens/CheckoutScreen/useCartOrder';
import { formatCurrency } from '@utils/formatCurrency';

import { AddCartItemForm } from './AddCartItemForm';

const defaultValues: DefaultValues<AddItemToCartFormVM> = {
  quantity: 1,
};

type AddItemToCartModalProps = ModalProps & {
  catalogItem?: CatalogItem;
  store?: OrderItemStoreVM;
};

export const AddItemToCartModal = ({
  catalogItem,
  store,
  ...modalProps
}: AddItemToCartModalProps) => {
  const methods = useForm<AddItemToCartFormVM>({
    resolver: zodResolver(addCartItemFormSchema),
    defaultValues,
  });

  const [itemAddedToCartModal, setItemAddedToCartModal] = useState<{
    show: boolean;
    item: AddOrderItemToCartVM | null;
  }>({ show: false, item: null });
  const closeCartItemAddedModal = () =>
    setItemAddedToCartModal((prev) => ({ ...prev, show: false }));
  const openCartItemAddedModal = (item: AddOrderItemToCartVM) =>
    setItemAddedToCartModal({ item, show: true });

  return (
    <>
      <ItemAddedToCartModal
        show={itemAddedToCartModal.show}
        cartItem={itemAddedToCartModal.item ?? undefined}
        onHide={closeCartItemAddedModal}
      />
      <Modal
        centered
        size="xl"
        fullscreen="lg-down"
        {...modalProps}
        onExited={() => methods.reset(defaultValues)}
      >
        <Modal.Body style={{ minHeight: 500 }}>
          <CloseButton
            onClick={modalProps.onHide}
            aria-label="Close modal"
            className="position-absolute top-0 end-0 mt-3 me-3"
          />
          <Row className="row mx-0 align-items-stretch py-lg-5 px-lg-5 py-xl-7 px-xl-7">
            <Col lg={6}>
              <div className="d-flex flex-column-reverse flex-lg-column">
                <div className="w-100 position-relative">
                  <StoreDetailsImageCarousel
                    objectFit="cover"
                    size={{ width: 420, height: 315 }}
                    images={catalogItem?.rawurl?.map((p) => ({ name: p, uri: p })) ?? []}
                  />
                </div>
                <div className="mt-4 mb-4 mb-lg-0">
                  <h4 className="text-dark fw-bold mb-0 text-break">{catalogItem?.name}</h4>
                  <hr className="my-3" />
                  {catalogItem?.price ? (
                    <h4 className="text-dark fw-bolder mb-0">
                      {formatCurrency(catalogItem?.price)}
                    </h4>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }}>
              <FormProvider {...methods}>
                {store && (
                  <AddCartItemForm
                    onSuccess={(item: AddOrderItemToCartVM) => {
                      modalProps.onHide?.();
                      openCartItemAddedModal(item);
                    }}
                    store={store}
                    catalogItem={catalogItem}
                  />
                )}
              </FormProvider>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
