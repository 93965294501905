import { ForwardedRef, forwardRef, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useRefinementList } from 'react-instantsearch';

import { PlaceSearch } from '@components';
import { useIsLaptopOrDesktop } from '@hooks';

import { CategoryFilterItem } from './CategoryFilterItem';
import { DeliveryOptionFilterItem } from './DeliveryOptionFilterItem';
import { DistanceFilterItem } from './DistanceFilterItem';
import { getTextBeforeSymbol } from './getTextBeforeSymbol';
import { useResetFilters } from './useResetFilters';

const getParentCategory = getTextBeforeSymbol('>');

export const CategoryFilterInner = (
  { parentCategory }: { parentCategory?: string },
  offcanvasContainer: ForwardedRef<unknown>
) => {
  const isDesktop = useIsLaptopOrDesktop();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { canReset, resetFilter } = useResetFilters({ withCategoriesFilter: !!parentCategory });
  const { items, ...categoryRefinements } = useRefinementList({
    attribute: 'categories.lvl1',
    sortBy: ['name:desc'],
    limit: 100,
  });

  const deliveryRefinements = useRefinementList({
    attribute: 'fulfillmentOptions',
    sortBy: ['name:desc'],
  });

  return (
    <>
      <Button
        size="sm"
        variant="accent"
        className="w-100 rounded-0 fixed-bottom d-lg-none"
        onClick={handleShow}
      >
        <i className="fi-filter me-2"></i>
        Filters
      </Button>
      <Offcanvas
        show={isDesktop ? true : show}
        onHide={handleClose}
        backdrop={isDesktop ? false : true}
        scroll={isDesktop ? true : false}
        container={offcanvasContainer}
        className="offcanvas-expand-lg"
      >
        <Offcanvas.Header closeButton className="border-bottom pt-lg-4 px-lg-0">
          <Offcanvas.Title as="h5">Filters</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="py-lg-4">
          <div className="pb-4 mb-2">
            <h3 className="h2 mb-2 font-title text-primary">Location</h3>
            <PlaceSearch />
          </div>
          {parentCategory && (
            <CategoryFilterItem
              {...categoryRefinements}
              items={items?.filter((item) => getParentCategory(item.label) === parentCategory)}
            />
          )}
          <DeliveryOptionFilterItem {...deliveryRefinements} />
          <DistanceFilterItem />
          {canReset && (
            <div className="border-top py-4">
              <Button onClick={resetFilter} className="rounded-pill" variant="outline-brownsugar">
                <i className="fi-rotate-right me-2"></i>
                Reset filters
              </Button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export const CategoryFilter = forwardRef(CategoryFilterInner);
